/**
 * All application-specific configuration should go here. Where possible, all
 * `process.env` refs should be contained to this file.
 *
 * This includes all 'tuneable' settings.
 */

import { initAxios } from "adready-api/init";
import axios from "axios";

// App specific config
const config = {
  // Auth API service, e.g., https://auth.adready.com/api
  AUTH_URL: null,

  // AdReady Plus API service, e.g., https://plus-api.adready.com/api
  API_URL: null,

  // AdReady URL, e.g., https://platform.adready.com
  ADREADY_URL: null,

  async init() {
    // Load some configs dynamically
    //
    // This solves the problem of baking a docker image with a static JS build
    // but still change certain config values dynamically based on the
    // environment on app startup.
    //
    // ALL per-ENV vars *MUST* be loaded from this endpoint!
    return axios
      .get("/api/config.json")
      .then((res) => {
        Object.assign(config, res.data);
      })
      .catch((e) => {
        // ignore missing file (likely in dev env)
        console.warn("Failed to load /api/config.json:", e);
        config.AUTH_URL ||= process.env.VUE_APP_AUTH_URL;
        config.API_URL ||= process.env.VUE_APP_API_URL;
        config.ADREADY_URL ||= process.env.VUE_APP_ADREADY_URL;

        const apiURL = `${config.ADREADY_URL}/api`;
        initAxios(apiURL);
      })
      .then(() => {
        // make sure we have all vars in dev
        config.AUTH_URL ||= process.env.VUE_APP_AUTH_URL;
        config.API_URL ||= process.env.VUE_APP_API_URL;
        config.ADREADY_URL ||= process.env.VUE_APP_ADREADY_URL;

        const apiURL = `${config.ADREADY_URL}/api`;
        initAxios(apiURL);
      });
  },
};

export default config;
