import Vue from "vue";

// font-awesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSignOut as falSignOut,
  faSlidersH,
  faAnalytics,
  faTimes as falTimes,
  faCopy as falCopy,
  faCheck as falCheck,
  faCode as falCode,
} from "@fortawesome/pro-light-svg-icons";

import {
  faCheck,
  faAngleRight as fasAngleRight,
  faAngleDown as fasAngleDown,
  faCircleNotch as fasCircleNotch,
  faTimes as fasTimes,
} from "@fortawesome/pro-solid-svg-icons";

import { faCheck as farCheck } from "@fortawesome/pro-regular-svg-icons";

// configure
library.add(
  faAnalytics,
  faSlidersH,
  farCheck,
  falSignOut,
  falTimes,
  faCheck,
  fasCircleNotch,
  fasTimes,
  fasAngleRight,
  fasAngleDown,
  falCopy,
  falCheck,
  falCode
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
