import Vue from "vue";
import VButton from "./components/elements/Button";
import vmodal from "vue-js-modal";
import "./utils/fonts";
import axios from "axios";
import VSelect from "vue-select";
import Clipboard from "v-clipboard";
import vueDebounce from "vue-debounce";
import config from "./config";
import Notifications from "vue-notification";

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

// load token from storage OR cookie
const token = localStorage.getItem("token");

Vue.prototype.$http.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Try to renew the token to get proper auth details
 *
 * @param {*} cookieToken
 * @param {*} next
 */

Vue.component("VButton", VButton);
Vue.component("VSelect", VSelect);
Vue.use(vmodal, { dynamic: true, injectModalsContainer: true, dialog: true });
Vue.use(Clipboard);
Vue.use(vueDebounce);
Vue.use(Notifications);

config.init().then(() => {
  const promises = [];
  const storeP = import("./store");
  const routerP = import("./router");
  const appP = import("./App");
  promises.push(storeP);
  promises.push(routerP);
  promises.push(appP);
  Promise.all(promises).then((myPromises) => {
    new Vue({
      router: myPromises[1].default,
      store: myPromises[0].default,
      render: (h) => h(myPromises[2].default),
    }).$mount("#app");
  });
});
